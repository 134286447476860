import { DAYS_IN_YEAR, MILLISECONDS_IN_DAY } from "~/constants/date";

export const ACCESS_TOKEN_NAME = "access-token";
export const CLIENT_ID_QUERY_TIMEOUT = 500;
export const FOREVER_COOKIE_LIFETIME_MILLISECONDS =
  100 * DAYS_IN_YEAR * MILLISECONDS_IN_DAY; // 100 years (forever)
export const CLIENT_ID_COOKIE_NAME = "clientId";
export const COOKIE_OPTIONS = { path: "/" };
export const LOCAL_STORAGE_LOGOUT_AT_KEY = "logoutAt";
export const LOCAL_STORAGE_LOGIN_AT_KEY = "loginAt";
export const LOCAL_STORAGE_RELOAD_AFTER_LOGOUT_DELAY = 500;
