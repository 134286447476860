const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
// add "dev" here to test in local development
const SENTRY_ENABLED_ENVIRONMENTS = [
  "development",
  "preproduction",
  "production"
];

const SENTRY_SOURCEMAPS_ENABLED_ENVIRONMENTS = ["preproduction", "production"];

// send errors to production environment unless other specified in env
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || "production";

const SENTRY_PLUGIN_ENABLED =
  SENTRY_DSN &&
  SENTRY_ENABLED_ENVIRONMENTS.includes(process.env.NEXT_PUBLIC_ENVIRONMENT);

const SENTRY_SOURCEMAPS_ENABLED =
  SENTRY_PLUGIN_ENABLED &&
  SENTRY_SOURCEMAPS_ENABLED_ENVIRONMENTS.includes(
    process.env.NEXT_PUBLIC_ENVIRONMENT
  );

const ERROR_RESIZE_OBSERVER_NAME = "ResizeObserver loop limit exceeded";
const IGNORED_ERRORS = [ERROR_RESIZE_OBSERVER_NAME];

const sentryConfig = {
  attachStacktrace: true,
  maxBreadcrumbs: 50,
  environment: SENTRY_ENVIRONMENT,
  dsn: SENTRY_DSN,
  ignoreErrors: IGNORED_ERRORS
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};

module.exports = {
  sentryConfig,
  SENTRY_PLUGIN_ENABLED,
  SENTRY_SOURCEMAPS_ENABLED
};
