/**
 * Factory function to create hook for safe context consuming
 * Don't ever export Context from your definition explicitly to prevent unsafe usage
 * Export only Provider and resulting hook instead
 */
import { useContext } from "react";

const createUseContext =
  <T>(Context: React.Context<T | undefined>, canonicalContextName: string) =>
  (): T => {
    const contextState = useContext(Context);

    if (contextState === undefined) {
      throw new Error(
        `use${canonicalContextName} must be used within a ${canonicalContextName}Provider`
      );
    }

    return contextState;
  };

export default createUseContext;
