import { NextPageContext } from "next";
import Router from "next/router";

import { NETWORK_CODES } from "~/constants/apiInteraction";

import { isServerSide } from "./common";

export const redirect = (
  { res }: NextPageContext,
  url: string,
  networkCode: number,
  options?: Record<string, unknown>
): void => {
  if (isServerSide()) {
    if (!res) {
      return;
    }
    res.setHeader("Location", url);
    res.statusCode = NETWORK_CODES.found;
  } else {
    Router.push(url, url, options);
  }
};
